import React from 'react';

import { useAppSelector } from '@app/store';
import { logout } from '@modules/auth';
import { useDispatch } from 'react-redux';
import { Typography } from '@mui/material';

import { EditProfileContainer, SaveButton } from './styled';
import useAlert from '@hooks/useAlert';
import { useDeleteProfileMutation } from '../../api/profile.api';

const DeleteProfile = () => {
  const { showSuccessToast, showErrorToast } = useAlert();
  const dispatch = useDispatch();
  const { userInfo } = useAppSelector(({ auth }) => auth);
  const [deleteProfile] = useDeleteProfileMutation();

  const submitHandler = async () => {
    try {
      await deleteProfile().unwrap();

      showSuccessToast('The profile info has been deleted');

      dispatch(logout());
    } catch (e: any) {
      if (e.data.error === 'INVALID_CREDENTIALS') {
        showErrorToast('The current password is incorrect');

        return;
      }

      showErrorToast('Something went wrong, try again later');
    }
  };

  return (
    <EditProfileContainer>

      <Typography maxWidth="40%" textAlign="center">
        To delete your profile press the
        {' '}
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          Delete profile
        </span>
        {' '}

        button. User with email
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {` ${userInfo.user.email} `}
        </span>
        will be deleted.
      </Typography>

      <SaveButton
        color="error"
        variant="contained"
        onClick={submitHandler}
      >
        Delete profile
      </SaveButton>
    </EditProfileContainer>
  );
};

export default DeleteProfile;
