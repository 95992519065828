import { createApi } from '@reduxjs/toolkit/query/react';
import { buildBaseQuery } from '@app/api';
import { IUser } from '@modules/auth';

export const profileApi = createApi({
  reducerPath: 'profileApi',
  keepUnusedDataFor: 0,
  baseQuery: buildBaseQuery(''),
  endpoints: (builder) => ({
    deleteProfile: builder.mutation<void, void>({
      query: () => ({
        url: 'auth/profile',
        method: 'DELETE',
      }),
    }),
  }),

});

export const { useDeleteProfileMutation } = profileApi;
