import { createApi } from '@reduxjs/toolkit/query/react';
import { buildBaseQuery } from '@app/api';

import { ILoginFormValues, ILoginResponse } from '../types/index';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: buildBaseQuery('auth'),
  endpoints: (builder) => ({
    login: builder.mutation<ILoginResponse, ILoginFormValues>({
      query: (body) => ({
        url: '/login',
        method: 'POST',
        body: {
          ...body, deviceId: 'browser', device: 'android',
        },
      }),
    }),

  }),

});

export const {
  useLoginMutation,
} = authApi;
