import PageTitle from '@ui/PageTitle';
import TextField from '@ui/TextField';
import { Button } from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from '@app/store';

import { useLoginMutation } from '../../api/auth.api';
import { setToken, setUser } from '../../store/auth.slice';
import { ILoginFormValues } from '../../types';
import { LoginFormBox, LoginFormInputs } from './styled';
import LoginFormValidation from '../../helpers/loginFormValidation';
import usePasswordInput from '@hooks/usePasswordInput';
import { Container, ErrorText } from '../styled';

const LoginForm = () => {
  const {
    inputType,
    inputProps,
  } = usePasswordInput();

  const dispatch = useAppDispatch();

  const [loginRequest, { isLoading, isError }] = useLoginMutation();

  const { handleSubmit, control, formState: { errors } } = useForm<ILoginFormValues>({
    resolver: yupResolver(LoginFormValidation),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit: SubmitHandler<ILoginFormValues> = async (data) => {
    try {
      const { accessToken, user } = await loginRequest(data).unwrap();

      dispatch(setToken({ accessToken }));

      dispatch(setUser({ user }));

      console.log('Logged in');
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Container>
      <LoginFormBox component="form" onSubmit={handleSubmit(onSubmit)}>
        <PageTitle textAlign="center">Sign In to delete your account</PageTitle>

        <LoginFormInputs>
          <Controller
            name="email"
            control={control}
            render={({
              field: {
                onChange,
                onBlur,
                value,
                name,
              },
            }) => (
              <TextField
                label="Email"
                placeholder="Email"
                type="email"
                fullWidth
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors?.email?.message}
                helperText={errors?.email?.message}
              />
            )}
          />

          <Controller
            name="password"
            control={control}
            render={({
              field: {
                onChange,
                onBlur,
                value,
              },
            }) => (
              <TextField
                label="Password"
                placeholder="Password"
                type={inputType}
                InputProps={inputProps}
                fullWidth
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors?.password?.message}
                helperText={errors?.password?.message}
              />
            )}
          />
        </LoginFormInputs>

        <ErrorText textAlign="center">
          {isError && 'Your credentials are invalid or the account is already deleted'}
        </ErrorText>

        <Button
          type="submit"
          fullWidth
          disabled={isLoading}
          variant="contained"
          color="success"
        >
          {isLoading ? 'Loading...' : 'Login'}
        </Button>

      </LoginFormBox>
    </Container>
  );
};

export default LoginForm;
