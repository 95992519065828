import Header from '@components/Header';
import { Navigate, Outlet } from 'react-router';
import { useAppSelector } from '@app/store';
import SuspenseLoader from '@components/SuspenseLoader';
import { PUBLIC_PAGES } from '@app/router/pages';

import { LayoutContainer } from '@layouts/styled';

const AuthLayout = () => {
  const { accessToken, userInfo } = useAppSelector(({ auth }) => auth);

  if (!accessToken) {
    return <Navigate to={PUBLIC_PAGES.LOGIN} />;
  }

  if (!userInfo.user) {
    return <SuspenseLoader />;
  }

  return (
    <LayoutContainer>
      <Header />

      <Outlet />
    </LayoutContainer>
  );
};

export default AuthLayout;
